<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="500"
    >
      <v-card :loading="loading">
        <v-card-title class="text-h5">
          Activate Member Registration
        </v-card-title>
        <v-card-text class="pt-4">
          <v-text-field
            v-model="referenceNo"
            label="Reference Number"
            dense
            class="black--text mb-4"
            type="text"
            maxLength="20"
          >
            <v-btn
              slot="append"
              color="info"
              small
              @click="getCodes"
              :disabled="referenceNo == ''"
            >
              Get Code
            </v-btn>
          </v-text-field>
          <v-autocomplete
            v-model="entry_type"
            :items="codes"
            item-text="value"
            item-value="value"
            dense
            clearable
            auto-select-first
            label="Entry type"
            :append-icon="icon.entry"
            :class="formErrorFields.entry_typeErrorMessage.length > 0 ? 'mb-3' : ''"
            :error-messages="formErrorFields.entry_typeErrorMessage"
            @change="selected"
          >
          </v-autocomplete>
          <span v-if="entry_type != ''">Available: {{ selectedCodeQuantity }}</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="close"
          >
            Cancel
          </v-btn>
          <v-btn
            color="success"
            text
            @click="submit"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <snackbar
      v-if="responseMessage.length > 0"
      :show="responseMessage.length > 0"
      :text="responseMessage"
      :color="responseMessageStatus"
      @close="responseMessage=''"
    />
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { mdiPackage } from '@mdi/js'
import formError from '@/composables/formErrorHandler/formError'
import crud from '@/composables/crud'
import snackbar from '@/components/SnackBar.vue'
import get from '@/composables/get'

export default {
  components: {
    snackbar,
  },

  props: {
    showDialog: Boolean,
    data: null,
    errorMessage: [],
  },

  setup(props, { emit }) {
    const icon = { entry: mdiPackage }
    const dialog = ref(props.showDialog)
    const referenceNo = ref('')
    const codes = ref([])
    const loading = ref(false)
    const entry_type = ref('')
    const formErrorFields = ref({
      entry_typeErrorMessage: '',
    })
    const selectedCodeQuantity = ref(0)
    const { store, responseMessageStatus, responseMessage } = crud()

    watch(() => props.showDialog, val => {
      dialog.value = val
    })

    watch(() => entry_type.value, code => {
      const index = codes.value.findIndex(c => c.value == code )
      selectedCodeQuantity.value = codes.value[index]['quantity']
    })

    watch(() => props.errorMessage, val => {
      formErrorFields.value.entry_typeErrorMessage = val
    })

    const selected = val => {
      formError().remove('entry_type', formErrorFields.value)
    }

    const close = () => {
      dialog.value = false
      emit('closeDialog')
    }

    const getCodes = async () => {
      codes.value = []
      await get(`${process.env.VUE_APP_URI}/api/cashiering/members/get-codes/${referenceNo.value}`, codes, null)
    }

    const submit = async () => {
      if(entry_type.value === '') {
        formErrorFields.value.entry_typeErrorMessage = "Entry type is required"
      } else {
        await store(`${process.env.VUE_APP_URI}/api/cashiering/members/activate`, loading, { 
          memberId: props.data.id, 
          entryType: entry_type.value,
          transactionNumber: referenceNo.value,
        }, formErrorFields)

        if (responseMessageStatus.value === 'success') {
          emit('updateTable')
          codes.value = []
          setTimeout(function() {
            emit('closeDialog')
          }, 2000)
        }
      }
    }

    return {
      referenceNo,
      codes,
      dialog,
      icon,
      entry_type,
      formErrorFields,
      formError,
      selected,
      selectedCodeQuantity,

      submit,
      getCodes,
      close,

      responseMessage,
      responseMessageStatus,
      loading,
    }
  },
}
</script>
